import * as z from 'zod'
import { StringToDate } from '@digital-magic/react-common/lib/utils/zod'
import { ShortDescription, TranslatedString, UrlString } from '@api/endpoints/types'

export const ProjectSettingsId = z.string().uuid()
export type ProjectSettingsId = z.infer<typeof ProjectSettingsId>

export const ProjectWebPage = UrlString
export type ProjectWebPage = UrlString

// TODO: Move to house model
export const ImmovableNumber = z.string().min(1).max(10)
export type ImmovableNumber = z.infer<typeof ImmovableNumber>

export const AccessToProperty = TranslatedString(ShortDescription)
export type AccessToProperty = z.infer<typeof AccessToProperty>

export const AccessInProperty = TranslatedString(ShortDescription)
export type AccessInProperty = z.infer<typeof AccessInProperty>

export const ProjectEncumbrances = TranslatedString(ShortDescription)
export type ProjectEncumbrances = z.infer<typeof ProjectEncumbrances>

export const ProjectDeveloper = TranslatedString(ShortDescription)
export type ProjectDeveloper = z.infer<typeof ProjectDeveloper>

export const MainContractor = TranslatedString(ShortDescription)
export type MainContractor = z.infer<typeof MainContractor>

export const ConstructionTimeline = TranslatedString(ShortDescription)
export type ConstructionTimeline = z.infer<typeof ConstructionTimeline>

export const EnergyClassId = z.string().uuid()
export type EnergyClassId = z.infer<typeof EnergyClassId>

export const EnergyClassTitle = TranslatedString(ShortDescription)
export type EnergyClassTitle = z.infer<typeof EnergyClassTitle>

export const FormOfOwnershipId = z.string().uuid()
export type FormOfOwnershipId = z.infer<typeof FormOfOwnershipId>

export const FormOfOwnershipTitle = TranslatedString(ShortDescription)
export type FormOfOwnershipTitle = z.infer<typeof FormOfOwnershipTitle>

export const PropertyTypeId = z.string().uuid()
export type PropertyTypeId = z.infer<typeof PropertyTypeId>

export const PropertyTypeTitle = TranslatedString(ShortDescription)
export type PropertyTypeTitle = z.infer<typeof PropertyTypeTitle>

export const ProjectSettings = z.object({
  id: ProjectSettingsId,
  webPage: ProjectWebPage,
  accessToProperty: AccessToProperty,
  accessInProperty: AccessInProperty,
  encumbrances: ProjectEncumbrances,
  developer: ProjectDeveloper,
  mainContractor: MainContractor,
  constructionTimeline: ConstructionTimeline,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type ProjectSettings = z.infer<typeof ProjectSettings>

export const ProjectSettingsUpdate = z.object({
  webPage: ProjectWebPage,
  accessToProperty: AccessToProperty,
  accessInProperty: AccessInProperty,
  encumbrances: ProjectEncumbrances,
  developer: ProjectDeveloper,
  mainContractor: MainContractor,
  constructionTimeline: ConstructionTimeline
})
export type ProjectSettingsUpdate = z.infer<typeof ProjectSettingsUpdate>

export const EnergyClassUpdate = z.object({
  title: EnergyClassTitle
})
export type EnergyClassUpdate = z.infer<typeof EnergyClassUpdate>

export const EnergyClass = EnergyClassUpdate.extend({
  id: EnergyClassId,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type EnergyClass = z.infer<typeof EnergyClass>

export const FormOfOwnershipUpdate = z.object({
  title: FormOfOwnershipTitle
})
export type FormOfOwnershipUpdate = z.infer<typeof FormOfOwnershipUpdate>

export const FormOfOwnership = FormOfOwnershipUpdate.extend({
  id: FormOfOwnershipId,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type FormOfOwnership = z.infer<typeof FormOfOwnership>

export const PropertyTypeUpdate = z.object({
  title: PropertyTypeTitle
})
export type PropertyTypeUpdate = z.infer<typeof PropertyTypeUpdate>

export const PropertyType = PropertyTypeUpdate.extend({
  id: PropertyTypeId,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type PropertyType = z.infer<typeof PropertyType>
